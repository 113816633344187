<template lang="pug">
include ../../../configs/template.pug
div.row
  div.col-12
    +field-validation('body.number', 'number', '["required"]')(type="number")
  div.col-12
    +select-validation('body.limitation', 'medicalLimitationsList', 'limitation', 'namelang', '["required"]')
  div(v-if="checkAccess('medicalStatement', 'enterDoctor')").col-12
    +select-validation('body.doctor', 'doctorsByMedInstitutionID(statement.medical_institution)', 'doctor', '"FIO"', '["required"]')
  div.cpl-sm-12.col-md-6
    +date-picker-validation('body.date_start', 'dateEffective', 'dateStartObject', '["required", "minValue", "maxValue"]')(
      min="1900-01-01" :max="body.date_end")
  div.cpl-sm-12.col-md-6
    +date-picker-validation('body.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')(
      :min="body.date_start" max="2200-12-31")
  div.col-12.d-flex.justify-center.mt-3
    v-btn(@click="transferApplication" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { sailorMedicalStatementTransfer } from '@/mixins/validationRules'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        number: null,
        limitation: null,
        doctor: null,
        date_start: null,
        date_end: null
      },
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['doctorsByMedInstitutionID']),
    ...mapState({
      namelang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      medicalLimitationsList: state => state.directory.limitations
    }),
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    },
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    }
  },
  validations () { return sailorMedicalStatementTransfer(this) },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
  },
  methods: {
    ...mapActions(['transferMedicalStatement']),

    /** Transfer statement to document */
    async transferApplication () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true
      const response = await this.transferMedicalStatement({ ...this.$route.params, body: this.body })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('transferredApplication')
      this.isLoading = false
    }
  }
}
</script>
